<template>
  <vca-row>
    <vca-checkbox
      v-model="value"
      id="open"
      :isArray="selected"
      @input="update()"
    >
      {{ $t("filter.deposits.status.open") }}
    </vca-checkbox>
    <vca-checkbox
      v-model="value"
      id="wait"
      :isArray="selected"
      @input="update()"
    >
      {{ $t("filter.deposits.status.wait") }}
    </vca-checkbox>
    <vca-checkbox
      v-model="value"
      id="confirmed"
      :isArray="selected"
      @input="update()"
    >
      {{ $t("filter.deposits.status.confirmed") }}
    </vca-checkbox>
  </vca-row>
</template>
<script>
export default {
  name: "DepositStatusFilter",
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      selected: true,
    };
  },
  methods: {
    update() {
      this.$emit("input", this.value);
    },
  },
};
</script>
