<template>
  <vca-row>
    <input
      type="radio"
      id="radio1"
      v-model="radio"
      name="lala"
      value="false"
      @input="update()"
    />
    <label for="radio1">{{ $t("filter.deposits.external.not") }}</label>
    <input
      type="radio"
      id="radio2"
      v-model="radio"
      name="lala"
      value="true"
      @input="update()"
    />
    <label for="radio2">{{ $t("filter.deposits.external.has") }}</label>
    <input
      type="radio"
      id="radio3"
      v-model="radio"
      name="lala"
      value="all"
      @input="update()"
    />
    <label for="radio3">{{ $t("filter.deposits.external.all") }}</label>
  </vca-row>
</template>
<script>
export default {
  name: "DepositExternalFilter",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    radio: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    update() {
      this.$emit("input", this.value);
    },
  },
};
</script>
